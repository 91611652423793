import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TelegramPost } from '../components/telegram-post'

class TgPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <NextPrevLinks
        >
          <li>
            {previous && <Link to={`/t${previous.fields.slug.replace('/', '')}`} rel="prev">←</Link>}
          </li>
          <PostDate>
            {post.frontmatter.date}
          </PostDate>
          <li>
            {next && <Link to={`/t${next.fields.slug.replace('/', '')}`} rel="next">→</Link>}
          </li>
        </NextPrevLinks>
        <TelegramPost postId={post.fields.slug}/>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Layout>
    )
  }
}

const PostDate = styled.p`
`;

const NextPrevLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  
  > li {
    min-width: 40px;
  }
  > li:last-child {
    text-align: right;
  }
`;

export default TgPostTemplate

export const pageQuery = graphql`
  query TgPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
      }
    }
  }
`
